import React from 'react'
import { Button, Classes, ControlGroup, Dialog, FormGroup, Icon, InputGroup, Menu, MenuItem } from '@blueprintjs/core'
// import { Select } from '@blueprintjs/select'
import { axios } from '../common'

import User from '../models/user'

// const RoleSelect = Select.ofType<Role>()

interface UserDialogProps {
  isOpen: boolean,
  user: any,
  onClose: (data: any, apply: boolean) => void
}

interface UserDialogState {
  users: User[],
  roles: any[],
  selected: User,
  activeRole: any,
  activeDepartment?: any
}

const Roles = [
  { id: 'user', label: 'Použivateľ'},
  { id: 'manager', label: 'Manager'}
]

export default class UsersDialog extends React.Component<UserDialogProps, UserDialogState> {
  constructor (props: UserDialogProps) {
    super(props)
    this.state = {
      users: [],
      roles: Roles,
      selected: new User({}),
      activeRole: Roles[0],
      activeDepartment: null
    }
    this.addUser = this.addUser.bind(this)
  }

  componentDidMount() {
    this.fetch()
  }

  async fetch () {
    const res = await axios.get('/users')
    this.setState({ users: res.data.users.map((user: any) => new User(user)) })
  }

  itemPredicate() {
    return true
  }

  roleRenderer(item: any, options: any) {
    return <MenuItem
      key={item.id}
      text={item.label}
      onClick={options.handleClick}
    />
  }

  // onRoleSelect(item: any) {
  //   const form = this.state.form
  //   form.role = item.id
  //   this.setState({ form, activeRole: item })
  // }

  update (event: any, field: string) {
    const selected = this.state.selected as any
    selected[field] = event.target.value
    selected.name = [selected.firstName, selected.lastName].filter(i => i).join(' ')
    const users = this.state.users
    const idx = users.findIndex(user => user.id === selected.id)
    if (typeof idx === 'undefined') {
      users.splice(idx, 1, selected)
    }
    this.setState({ selected, users })
  }

  addUser() {
    const user = new User({})
    this.state.users.push(user)
    this.setState({ users: this.state.users, selected: user })
  }

  renderUsers() {
    const users = this.state.users.map((user, index) => <MenuItem
      id={user.id}
      key={`user-item-${index}`}
      text={user.name}
      active={this.state.selected.id === user.id}
      icon={<Icon icon="user" color={this.state.selected.id === user.id ? 'white' : user.color}/>}
      onClick={() => {
        this.setState({ selected: user })
      }}
    />)
    return <Menu>{ users }</Menu>
  }

  render () {
    return <Dialog
      isOpen={this.props.isOpen}
      title="Použivatelia"
      style={{ width: 480 }}
      onClose={() => { this.props.onClose(this.state.selected, false) }}
    >
      <div className="edit-dialog">
        <div className="row">
          <div className="column" style={{ flex: 1 }}>
            <FormGroup
              label="Použivatelia">
              { this.renderUsers() }
            </FormGroup>
            <Button onClick={this.addUser} minimal text="Pridať" icon="plus" style={{ marginTop: 10 }}/>
          </div>
          <div className="column" style={{ flex: 1 }}>
            <FormGroup
              label="Meno">
              <InputGroup
                placeholder="Meno"
                className="text-input-plain"
                value={this.state.selected.firstName}
                onChange={(event: any) => this.update(event, 'firstName')}
              />
            </FormGroup>

            <FormGroup
              label="Priezvisko">
              <InputGroup
                placeholder="Priezvisko"
                className="text-input-plain"
                value={this.state.selected.lastName}
                onChange={(event: any) => this.update(event, 'lastName')}
              />
            </FormGroup>

            <ControlGroup fill>
              <FormGroup
                label="Farba">
                <InputGroup
                  placeholder="Farba"
                  className="text-input-plain"
                  value={this.state.selected.color}
                  onChange={(event: any) => this.update(event, 'color')}
                />
              </FormGroup>
            </ControlGroup>

            <FormGroup
              label="Použivateľské meno">
              <InputGroup
                placeholder="Použivateľské meno"
                className="text-input-plain"
                value={this.state.selected.userName}
                onChange={(event: any) => this.update(event, 'userName')}
              />
            </FormGroup>

            <FormGroup
              label="Heslo">
              <InputGroup
                placeholder="Heslo"
                className="text-input-plain"
                value={this.state.selected.password}
                type="password"
                onChange={(event: any) => this.update(event, 'password')}
              />
            </FormGroup>
          </div>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text="Zrušiť"
              icon="cross"
              onClick={() => { this.props.onClose(this.state.selected, false) }}
            />

            <Button
              text="Uložiť"
              icon="tick"
              intent="primary"
              className="m-r-10"
              onClick={() => { this.props.onClose(this.state.selected, true) }}
            />
          </div>
        </div>

      </div>
    </Dialog>
  }
}