import React from 'react'
import { Button, Classes, Dialog, FormGroup, Icon, InputGroup, Menu, MenuItem, Colors } from '@blueprintjs/core'
// import { Select } from '@blueprintjs/select'
import { axios } from '../common'

import Room from '../models/room'

// const RoleSelect = Select.ofType<Role>()

interface RoomDialogProps {
  isOpen: boolean,
  onClose: (data: any, apply: boolean) => void
}

interface RoomDialogState {
  rooms: Room[],
  selected: Room,
}

export default class RoomsDialog extends React.Component<RoomDialogProps, RoomDialogState> {
  constructor (props: RoomDialogProps) {
    super(props)
    this.state = {
      rooms: [],
      selected: new Room({})
    }
    this.addRoom = this.addRoom.bind(this)
  }

  componentDidMount() {
    this.fetch()
  }

  async fetch () {
    const res = await axios.get('/rooms')
    this.setState({ rooms: res.data.rooms.map((room: any) => new Room(room)) })
  }


  update (event: any, field: string) {
    const selected = this.state.selected as any
    selected[field] = event.target.value
    const rooms = this.state.rooms
    const idx = rooms.findIndex(room => room.id === selected.id)
    if (typeof idx === 'undefined') {
      rooms.splice(idx, 1, selected)
    }
    this.setState({ selected, rooms })
  }

  addRoom() {
    const room = new Room({})
    this.state.rooms.push(room)
    this.setState({ rooms: this.state.rooms, selected: room })
  }

  renderRooms() {
    const rooms = this.state.rooms.map((room, index) => <MenuItem
      id={room.id}
      key={`room-item-${index}`}
      text={room.name}
      active={this.state.selected.id === room.id}
      icon={<Icon icon="home" color={this.state.selected.id === room.id ? 'white' : Colors.BLUE3}/>}
      onClick={() => {
        this.setState({ selected: room })
      }}
    />)
    return <Menu>{ rooms }</Menu>
  }

  render () {
    return <Dialog
      isOpen={this.props.isOpen}
      title="Miestnosti"
      style={{ width: 480 }}
      onClose={() => { this.props.onClose(this.state.selected, false) }}
    >
      <div className="edit-dialog">
        <div className="row">
          <div className="column" style={{ flex: 1 }}>
            <FormGroup
              label="Miestnosti">
              { this.renderRooms() }
            </FormGroup>
            <Button onClick={this.addRoom} minimal text="Pridať" icon="plus" style={{ marginTop: 10 }}/>
          </div>
          <div className="column" style={{ flex: 1 }}>
            <FormGroup
              label="Názov">
              <InputGroup
                placeholder="Názov"
                className="text-input-plain"
                value={this.state.selected.name}
                onChange={(event: any) => this.update(event, 'name')}
              />
            </FormGroup>
          </div>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text="Zrušiť"
              icon="cross"
              onClick={() => { this.props.onClose(this.state.selected, false) }}
            />

            <Button
              text="Uložiť"
              icon="tick"
              intent="primary"
              className="m-r-10"
              onClick={() => { this.props.onClose(this.state.selected, true) }}
            />
          </div>
        </div>

      </div>
    </Dialog>
  }
}