import { axios } from './common'
import User from './models/user'

class Session {
  token?: string
  user: User
  loggedIn: boolean = false
  
  constructor() {
    this.user = new User({})
    this.restore()
    this.destroy = this.destroy.bind(this)
  }

  destroy() {
    localStorage.removeItem('user')
    this.user = new User({})
    this.token = undefined
    this.loggedIn = false
    window.location.pathname = '/'
  }

  restore() {
    const userJSON = localStorage.getItem('user')
    if (userJSON) {
      this.user = new User(JSON.parse(userJSON))
      this.loggedIn = true
    }
  }

  async save() {
    if (this.user) {
      localStorage.setItem('user', JSON.stringify(this.user))
    } else {
      throw new Error("Invalid Session Token or User")
    }
  }

  async login(userName: string, password: string) {
    try {
      const response = await axios.post('/auth/login', { userName, password })
      this.user = new User(response.data.user)
      this.loggedIn = true
      await this.save()
      return { user: this.user }
    } catch (e) {
      console.error('login failure', e)
      throw new Error("Login Failure")
    }
  }
}

const session = new Session()
export default session