import BaseModel from './base_model'

export default class Room extends BaseModel {
  name: string

  constructor(data: any) {
    super(data.id)
    this.name = data.name || ''
  }
}
