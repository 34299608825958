import React, { Component } from 'react'
import { Button, Dialog, FormGroup, Icon, InputGroup, Classes, MenuItem } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import { DateInput } from '@blueprintjs/datetime'
import Presence from '../models/presence'
import User from '../models/user'
import { formatDate, parseDate, formatDateTime, parseDateTime } from '../common'
import deepEqual from 'deep-equal'

const UserSelect = Select.ofType<User>()

interface Props {
  presence: Presence
  users: User[]
  onClose: () => void
  onSave: (presence: Presence) => void
}

interface State {
  form: Presence
}

export default class PresenceDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      form: new Presence(this.props.presence)
    }

    this.onSave = this.onSave.bind(this)
    this.onUserSelect = this.onUserSelect.bind(this)
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(prevProps.presence, this.props.presence)) {
      this.setState({ form: this.props.presence })
    }
  }

  onSave() {
    this.props.onSave(this.state.form)
  }

  userRenderer(user: User, options: any) {
    return <MenuItem
      key={`presence-${user.id}`}
      id={user.id}
      text={user.name}
      icon={<Icon icon="user" color={user.color}/>}
      onClick={options.handleClick}
    />
  }

  onUserSelect(user: User) {
    const form = this.state.form
    form.user_id = user.id
    form.user = user
    this.setState({ form })
  }

  render() {
    return <Dialog
      isOpen={true}
      onClose={this.props.onClose}
      title="Nastavenie služby lekára">
      <div className={Classes.DIALOG_BODY}>
        <div className="column">
          <FormGroup label="Od" inline={true}>
            <DateInput
              formatDate={ formatDateTime }
              parseDate={ parseDateTime }
              value={this.state.form.start}
              onChange={date => {
                const form = this.state.form
                form.start = date
                this.setState({ form })
              }}
            />
          </FormGroup>
          <FormGroup label="Do" inline={true}>
            <DateInput
              formatDate={ formatDateTime }
              parseDate={ parseDateTime }
              value={this.state.form.end}
              timePrecision="minute"
              onChange={date => {
                const form = this.state.form
                form.end = date
                this.setState({ form })
              }}
            />
          </FormGroup>
          <FormGroup label="Lekár" inline={true}>
            <UserSelect
              items={this.props.users}
              activeItem={this.state.form.user}
              itemRenderer={this.userRenderer}
              onItemSelect={this.onUserSelect}>
              <Button text={this.state.form.user.exists ? this.state.form.user.name : 'Vyberte'} rightIcon="caret-down"/>
            </UserSelect>
          </FormGroup>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Zavrieť" icon="cross" minimal onClick={this.props.onClose} />
          <Button text="Uložiť" icon="tick" intent="success" onClick={this.onSave} />
        </div>
      </div>
    </Dialog>
  }
}
