import BaseModel from './base_model'

export default class Patient extends BaseModel {
  name: string
  note: string
  race: string

  constructor(data: any) {
    super(data.id)
    this.name = data.name || ''
    this.note = data.note || ''
    this.race = data.race || ''
  }
}