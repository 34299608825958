import { createStore } from 'redux'
import Session from '../session'
import User from '../models/user'

export const SET_LOGIN_DATA = 'SET_LOGIN_DATA'
export const SET_USER = 'SET_USER'


interface SetLoginDataAction {
  type: string
  user: User
}

interface SetUserAction {
  type: string,
  user: User
}

export type ActionTypes = SetLoginDataAction | SetUserAction

export function setUser(user: User) : ActionTypes {
  return {
    type: SET_USER,
    user
  }
}

export function setLoginData(user: User) {
  return {
    type: SET_LOGIN_DATA,
    user
  }
}

export interface AppState {
  user: User
}

export const initialState : AppState = ({
  user: Session.user
})

export function reducer(state : AppState = initialState, action: ActionTypes) : AppState {
  const newState : AppState = Object.assign({}, state)
  switch (action.type) {
    case SET_LOGIN_DATA:
      const { user } = action as SetLoginDataAction
      newState.user = user
      break
    case SET_USER:
      newState.user = (action as SetUserAction).user
      break
  }

  return newState
}

export default createStore(reducer)