import { IconName } from '@blueprintjs/core'

export default class Action {
  id: string
  title: string
  icon: IconName

  constructor(id: string, title: string, icon: IconName) {
    this.id = id
    this.title = title
    this.icon = icon
  }
}

export const Actions = [
  new Action('sms', 'SMS', 'mobile-phone'),
  new Action('week_templates', 'Služby', 'time'),
  new Action('users', 'Použivatelia', 'user'),
  new Action('rooms', 'Miestnosti', 'home')
]