import Axios from 'axios'
import { BusEvent } from 'react-event-bus'
import { baseURL } from './settings'
import moment from 'moment'
import Client from './models/client'

export const DEFAULT_HOUR = 4;
export const DAY_MS = 1000 * 3600 * 24;

Axios.defaults.withCredentials = true;

export const axios = Axios.create({
  baseURL,
  timeout: 60000,
  withCredentials: true
});

export const IsoDateFormat = 'YYYY-MM-DD';
export const DateFormat = 'DD.MM.YYYY';
export const DateTimeFormat = 'DD.MM.YYYY HH:mm';
export const TimeFormat = 'hh:mm';

export class DateFormatter {
  static formatDate(date: Date, locale: string | undefined, useTime: boolean = false) {
    return moment(date).format(useTime ? DateTimeFormat : DateFormat)
  }

  static parseDate(date: string, locale: string | undefined) {
    return moment(date, DateTimeFormat).toDate()
  }
}

export class SmsEvent implements BusEvent {
  type: string = 'sms'
  value: any = true
  number: string = ''
  client: Client = new Client({})
}

export class SmsUpdateEvent implements BusEvent {
  type: string = 'sms_update'
  value: any = true
};

export const today = () => {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth(), now.getDate(), DEFAULT_HOUR, 0)
};

export const yesterday = () => {
  const now = today()
  return new Date(now.getTime() - DAY_MS)
};

export const weekAgo = () => {
  const now = today()
  return new Date(now.getTime() - (7 * DAY_MS))
};

export const tommorow = () => {
  const now = today()
  return new Date(now.getTime() + DAY_MS)
};

export const formatIsoDate = (date: string | Date) => {
  return moment(date).format(IsoDateFormat)
};

export const formatDate = (date: string | Date) => {
  return moment(date).format(DateFormat)
};

export const formatTime = (date: string | Date) => {
  return moment(date).format(TimeFormat)
};

export const formatDateTime = (date: string | Date) => {
  return moment(date).format(DateTimeFormat)
};

export const formatDay = (date: string | Date) => {
  return moment(date).format('dddd').toUpperCase()
};

export const parseIsoDate = (date: string | Date) => {
  return moment(date, IsoDateFormat).toDate()
};

export const parseDate = (date: string | Date) => {
  return moment(date, DateFormat).toDate()
};

export const parseTime = (date: string | Date) => {
  return moment(date, TimeFormat).toDate()
};

export const parseDateTime = (date: string | Date) => {
  return moment(date, DateTimeFormat).toDate()
};

export const hasString = (string: string, minLength: number = 1) => {
  return string.length >= minLength
};

export const hasStrings = (strings: Array<string>, minLength: number = 1) => {
  let valid = true
  for (let i = 0; i < strings.length; i++) {
    if (!hasString(strings[i])) {
      valid = false
      break
    }
  }
  return valid
}

export const formatNumber = (valueString: string, valueNumber: number) => {
  if (isNaN(valueNumber)) {
    valueString = '0'
  }

  let value = valueString
  let decimalGuard = valueString.match(/\./g)
  if (decimalGuard && decimalGuard.length > 1) {
    const split = valueString.split('.')
    value = `${split[0]}.${split[1]}`
  }

  let endsWithDecimal = value[value.length - 1] === '.'

  if (value.length === 2 && value[0] === '0' && !endsWithDecimal) {
    value = value[1]
  }

  if (endsWithDecimal) {
    if (value.length <= 1) {
      return '0'
    }
    return value
  } else {
    if (value.length === 0) {
      return ''
    }
    return value
  }
}

export const convertHex = (hex: string,opacity: number) => {
  hex = hex.replace('#','')
  const r = parseInt(hex.substring(0,2), 16)
  const g = parseInt(hex.substring(2,4), 16)
  const b = parseInt(hex.substring(4,6), 16)
  return `rgba(${r},${g},${b}, ${opacity/100})`
}

const padZero = (str: string, len = 2) => {
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len)
}

export const invertColor = (hex: string) => {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  
  if (hex.length !== 6) {
    return '#0080c0';
  }
  
  // invert color components
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b);
}
