import React from 'react'
import logo from '../assets/Logo_zverland_web.gif'
import { Button, Classes, Dialog, FormGroup, InputGroup, ControlGroup } from '@blueprintjs/core'
import { hasStrings } from '../common'
import Session from '../session'
import User from '../models/user'

interface LoginProps {
  onLogin: (user: User) => void
}

interface LoginState {
  userName: string,
  password: string,
  error: boolean
}

export default class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props)
    this.state = {
      userName: '',
      password: '',
      error: false
    }
  }

  async login() {
    const userName = this.state.userName
    const password = this.state.password
    if (hasStrings([userName, password])) {
      try {
        const { user } = await Session.login(userName, password)
        this.props.onLogin(user)
      } catch (e) {
        console.error('e', e)
        this.setState({ error: true })
      }
    }
  }

  update(event: any, field: string) {
    const state : any = {}
    state[field] = event.target.value
    this.setState(state)
  }

  renderError() {
    if (!this.state.error) {
      return null
    }

    return (<div className="row login-error">
      Nesprávne prihlasovacie údaje
    </div>)
  }

  render() {
    return <div className="login center-column">
      <div className="center-row">
        <Dialog isOpen={true} style={{ width: 340 }}>
          <div className={Classes.DIALOG_HEADER}>
            <strong>Veterinárna klinika ZVERLAND - Prihlásenie</strong>
          </div>
          <div className={Classes.DIALOG_BODY}>
            <div className="login-content">
              <img alt="logo" className="logo-zverland-top" src={logo}/>
              <div className="column">
                <ControlGroup fill style={{width: '100%'}}>
                  <FormGroup
                    label="Použivateľ">
                    <InputGroup
                      placeholder="Použivateľ"
                      className="text-input-plain"
                      value={this.state.userName}
                      onChange={(event: any) => this.update(event, 'userName')}
                    />
                  </FormGroup>
                </ControlGroup>

                <ControlGroup fill style={{width: '100%'}}>
                  <FormGroup
                    label="Heslo">
                    <InputGroup
                      placeholder="Heslo"
                      className="text-input-plain"
                      value={this.state.password}
                      onChange={(event: any) => this.update(event, 'password')}
                      type="password"
                    />
                  </FormGroup>
                </ControlGroup>

                { this.renderError() }
              </div>
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <div className="row" style={{justifyContent: 'flex-end'}}>
                <Button onClick={() => { this.login() }} text="Potvrdiť" icon="tick" intent="primary" />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  }
}