export default class BaseModel {
  id: string

  constructor(id: string) {
    this.id = id
  }

  get exists() : boolean {
    return typeof this.id === 'string' && this.id.length > 0
  }

  get rest() : 'put' | 'post' {
    return this.exists ? 'put' : 'post'
  }
}