import { Eventable } from '@patterns/eventable';
import React from "react";
import session from "./session";

export type LiveMessage = {
  action: 'authorize' | 'pong' | 'notify' | 'global-bell'
  data: any
}

export enum LiveEvents {
  ClientChange = 'live:client_change',
  Disconnected = 'live:disconnected',
  TimedChange = 'live:timed_change',
  Notification = 'live:notification',
  Pong = 'live:pong',
  Ready = 'live:ready',
  Bell = 'live:bell',
  GlobalBell = 'live:global-bell',
  ForceReload = 'live:force_reload'
}

class LiveService extends Eventable {
  socket?: WebSocket;
  isReady = false;
  keepAliveInterval: number = -1;
  lastAlive = new Date(0);
  failure = -1
  connectInterval = -1;
  reachabilityHandle = -1;

  connect = async () => {
    const isDev = window.location.host === 'localhost:3000';
    const protocol = isDev ? 'ws' : 'wss';
    const host = isDev ? 'localhost' : window.location.host;
    const suffix = isDev ? ':8090' : '/live';
    const url = `${protocol}://${host}${suffix}`;
    // const url = `http://zverland.quanto.sk:8090`;
    clearInterval(this.connectInterval);
    console.log('ws url', url);
    this.socket = new WebSocket(url);
    this.socket.onmessage = this.onMessage;
    this.socket.onopen = this.onOpen;
    this.socket.onclose = this.onClose;
  }

  keepalive = () => {
    return window.setInterval(() => {
      this.reachabilityHandle = window.setTimeout(() => {
        console.error('[Live Service] => Reachability failure');
        this.notify(LiveEvents.Disconnected);
      }, 29000);
      this.send('ping', { timestamp: new Date().getTime() });
    }, 30000);
  }

  onOpen = (evt: Event) => {
    console.log('live on open');
    if (this.connectInterval) {
      clearInterval(this.connectInterval);
    }

    this.isReady = true;
  }

  onClose = (evt: Event) => {
    console.log('live on close');
    this.socket = undefined;
    this.isReady = false;
    if (this.keepAliveInterval) {
      clearInterval(this.keepAliveInterval);
      clearInterval(this.reachabilityHandle);
      this.connectInterval = window.setInterval(() => {
        this.connect()
      }, 10000);
    }
    this.notify(LiveEvents.Disconnected);
  }

  onMessage = (evt: MessageEvent) => {
    console.log('got msg', evt);
    this.isReady = true;
    this.lastAlive = new Date();
    const data = JSON.parse(evt.data) as LiveMessage;
    
    if (data.action === 'authorize') {
      if (data.data.success) {
        if (this.keepAliveInterval) {
          clearInterval(this.keepAliveInterval);
        }
        this.keepAliveInterval = this.keepalive();
        this.notify(LiveEvents.Ready, data);
      }
    }

    if (data.action === 'global-bell') {
      // this.notify(LiveEvents.GlobalBell, data);
      this.notify(LiveEvents.Notification, data)
    }

    if (data.action === 'pong') {
      const now = new Date().getTime();
      clearTimeout(this.reachabilityHandle);
      console.log(`[Live Service] => keepalive (${now - data.data.timestamp}ms)`);
      this.notify(LiveEvents.Pong, data);
    }

    if (data.action === 'notify') {
      this.notify(LiveEvents.Notification, data)
    }
  }

  send = (action: string, data: any) => {
    const message = JSON.stringify({ action, data });

    console.log('sending msg', message, this.isReady, this.socket, this.socket!.readyState);
    if (this.isReady && this.socket && this.socket.readyState === WebSocket.OPEN) {
      console.log('rly sending msg', message);
      this.socket?.send(message);
    }
  }

  private authorize = () => {
    const message = JSON.stringify({
      action: 'authorize',
      data: {
        token: session.token
      }
    });
    this.socket?.send(message)
  }
}

type LiveContextType = {
  live: LiveService
}

export const live = new LiveService();
export const LiveContext = React.createContext<LiveContextType>({ live });
