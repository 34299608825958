import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withEvents } from 'react-event-bus'
import { AxiosResponse, AxiosError } from 'axios'

import { axios, SmsEvent } from './common'

import Login from './views/login'
import Calendar from './pages/calendar'

import UsersDialog from './components/users_dialog'
import RoomsDialog from './components/rooms_dialog'

import Action from './models/action'
import Room from './models/room'
import User from './models/user'
import Session from './session'

import {
  AppState,
  setLoginData,
} from './store/store'
import { Notifications } from './notifications'

interface MainStateProps {
  user: User
}

interface MainDispatchProps {
  onLogin(user: User): void,
  onLogout(): void
}

interface MainOwnProps {
}

type MainProps = MainStateProps & MainDispatchProps & MainOwnProps

const mapStateToProps = (state: AppState, ownProps: MainOwnProps) : MainStateProps => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch: Function, ownProps: MainOwnProps) : MainDispatchProps => {
  return {
    onLogin: (user: User) => {
      dispatch(setLoginData(user))
    },
    onLogout: () => {
      Session.destroy()
      dispatch(setLoginData(new User({})))
    }
  }
}

export const Main: React.SFC<MainProps> = props => {
  const [showRooms, setShowRooms ] = useState(false)
  const [showUsers, setShowUsers ] = useState(false)
  const calendarRef = React.createRef<Calendar>()

  console.log('main props, props', props);
  axios.interceptors.response.use((response: AxiosResponse) => response, (error: AxiosError) => {
    if ((error.response as AxiosResponse).status === 403) {
      Session.destroy()
    }
    return Promise.reject(error)
  })

  if (Session.loggedIn) {

    if (Session.user.exists && !props.user) {
      props.onLogin(Session.user)
    }

    const onAction = (action: Action) => {
      if (action.id === 'users') {
        setShowUsers(true)
      }
      if (action.id === 'rooms') {
        setShowRooms(true)
      }
      if (action.id === 'sms') {
        const event = new SmsEvent();
        (props as any).EventBus.dispatchEvent(event)
      }
    }

    return <div className="gargo">
        <div className="content-manager">
          <Calendar
            ref={calendarRef}
            user={props.user}
            onLogout={props.onLogout}
            onAction={onAction}
          />
        </div>

        { showUsers && <UsersDialog
          user={props.user}
          isOpen={showUsers}
          onClose={async (user: User, apply: boolean) => {
            if (apply) {
              if (user.exists) {
                await axios.put(`/users/${user.id}`, user)
              } else {
                await axios.post('/users', user)
              }
              if (calendarRef.current) {
                calendarRef.current.fetchAdditional()
              }
            }
            setShowUsers(false)
          }} /> }

        { showRooms && <RoomsDialog  
          isOpen={showRooms}
          onClose={async (room: Room, apply: boolean) => {
            if (apply) {
              if (room.exists) {
                await axios.put(`/rooms/${room.id}`, room)
              } else {
                await axios.post('/rooms', room)
              }
              if (calendarRef.current) {
                calendarRef.current.fetchAdditional()
              }
            }
            setShowRooms(false)
          }} /> }
      <Notifications/>
    </div>
  } else {
    return <Login onLogin={props.onLogin}/>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withEvents(Main))
