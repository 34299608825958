import moment from 'moment'
import { today } from '../common'
import BaseModel from './base_model'

import Client from './client'
import Patient from './patient'
import User from './user'
import Room from './room'

export enum CalendarEventType {
  JOB = 0,
  VACATION = 1,
  OTHER = 2
}

export default class CalendarEvent extends BaseModel {
  start: Date
  end: Date
  allDay: boolean
  note: string
  client: Client
  client_id: string
  patient: Patient
  patient_id: string
  user: User
  user_id: string
  duration: number
  type: CalendarEventType
  dragable: boolean
  editable: boolean
  title: string
  color: string
  room: Room
  resourceId: string
  resourceEditable: boolean
  present: boolean
  recall: number
  finished: boolean

  constructor(data: any) {
    super(data.id);
    this.start = data.start ? new Date(data.start) : today();
    this.end = data.end ? new Date(data.end) : moment(this.start).add(30, 'minutes').toDate();
    this.duration = data.duration || 0;
    this.allDay = data.allDay || false;
    this.client_id = data.client_id;
    this.client = new Client(data.client || {});
    this.patient_id = data.patient_id;
    this.patient = new Patient(data.patient || {});
    this.user_id = data.user_id;
    this.user = new User(data.user || {});
    this.note = data.note || '';
    this.type = data.type || CalendarEventType.JOB;
    this.editable = true;
    this.resourceEditable = true;
    this.dragable = true;
    this.color = data.user ? data.user.color : '#08c';
    this.room = data.room ? new Room(data.room) : new Room({});
    this.title = '';
    this.resourceId = data.room ? data.room.id : '434e3261-4a2e-419b-8ec8-ec9324cf495f';
    this.refresh();;
    this.present = data.present;
    this.recall = data.recall || 0;
    this.finished = typeof data.finished === 'undefined' ? false : data.finished;
  }

  refresh() {
    this.title = `${this.client.name} ${this.note && this.note.length > 1 ? '(' + this.note + ')' : ''}`
    // if (this.type === 0) {
    //   this.allDay = false
    // } else {
    //   this.title = `${[this.user ? this.user.name : '', this.note].join(' ')}`
    //   this.allDay = true
    // }
    console.log('type', this.type, 'allday',this.allDay);
  }

  get isPast() {
    const now = new Date().getTime()
    return this.start.getTime() < now
  }
}