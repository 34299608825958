import { Notifier } from '@patterns/ui';
import * as React from 'react';
import useSound from 'use-sound';
import { live, LiveEvents, LiveMessage } from './live';

const bell = require('./assets/bell.m4a');

interface Props {}

let initialized = false;

export function Notifications(props: Props) {
  // initialSkip to prevent double subscription triggered on play change
  const [play] = useSound(bell);
  
  React.useEffect(() => {
    if (initialized) {
      const onMessage = (data: LiveMessage) => {
        play();
        Notifier.success(`Pacient prítomný: ${(data.data.client || {name: ''}).name} (${data.data.doctor})`, 'user');
      };

      live.observe(LiveEvents.Notification, onMessage);
      return () => { 
        live.cancel(LiveEvents.Notification, onMessage);
      }
    } else {
      initialized = true
    }
  }, [ play ]);

  return <div/>
}
