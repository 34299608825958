import React from 'react'
import { Provider } from 'react-redux'

import './App.scss'
import 'normalize.css/normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/select/lib/css/blueprint-select.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '../node_modules/react-vis/dist/style.css'
import '../node_modules/@fullcalendar/daygrid/main.css'

import Main from './Main'
import store from './store/store'
import { EventProvider } from 'react-event-bus'

const App: React.FC = () => {
  return <Provider store={store}>
    <EventProvider>
      <Main />
    </EventProvider>
  </Provider>
}

export default App;
