import React from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import CalendarEventDialog from '../components/calendar_event_dialog'
import PresenceDialog from '../components/presence_dialog'
import NavBar from '../components/navbar'
import { Spinner } from '@blueprintjs/core'
import { axios, yesterday, today, convertHex, invertColor } from '../common'
import CalendarEvent from '../models/calendar_event'
import Session from '../session'
import Action from '../models/action'
import User from '../models/user'
import Room from '../models/room'
import moment from 'moment'
import Presence from '../models/presence'
import { live, LiveEvents } from '../live';
import Queue from 'promise-queue';

const queue = new Queue(1, Infinity);

export class DisplayMode {
  value: string
  title: string

  constructor(value: string, title: string) {
    this.value = value
    this.title = title
  }
}

export enum DisplayModeType {
  DAY = 'resourceTimeGridDay',
  WEEK = 'timeGridWeek',
  MONTH = 'dayGridMonth'
}

export const DisplayModes = [
  new DisplayMode('resourceTimeGridDay', 'Deň'),
  new DisplayMode('timeGridWeek', 'Týždeň'),
  new DisplayMode('dayGridMonth', 'Mesiac')
]

const weekDays = ['Nedela', 'Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota']

const locale = {
  code: "sk",
  buttonText: {
    prev: "Predchádzajúci",
    next: "Nasledujúci",
    today: "Dnes",
    month: "Mesiac",
    week: "Týždeň",
    day: "Deň",
    list: "Rozvrh"
  },
  weekLabel: "Ty",
  allDayText: "Celý deň",
  eventLimitText: (n: any)  => `+ďalšie: ${n}`,
  noEventsMessage: "Žiadne akcie na zobrazenie"
}


interface Props {
  user: User
  onAction: (action: Action) => void
  onLogout: () => void
}

interface State {
  isLoading: boolean
  window: any
  title: string
  selected: CalendarEvent
  selectedPresence: Presence
  showDialog: boolean
  showPresence: boolean
  events: CalendarEvent[]
  presences: Presence[]
  users: User[]
  rooms: Room[]
  dateStart: Date
  dateEnd: Date
  displayMode: DisplayMode
  userFilter: User
  clipboard: CalendarEvent[]
  clipboardType: DisplayModeType;
}

export default class Calendar extends React.Component<Props, State> {
  calendarRef: any = React.createRef()

  constructor(props: Props) {
    super(props)
    this.state = {
      isLoading: true,
      title: '',
      window: {
        width: 900,
        height: 500
      },
      dateStart: yesterday(),
      dateEnd: today(),
      events: [],
      presences: [],
      selected: new CalendarEvent({}),
      selectedPresence: new Presence({}),
      showDialog: false,
      showPresence: false,
      users: [],
      rooms: [],
      userFilter: new User({}),
      displayMode: DisplayModes[0],
      clipboard: [],
      clipboardType: DisplayModeType.DAY
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.nextNext = this.next.bind(this);
    this.prevPrev = this.prev.bind(this);
    this.onDatesRender = this.onDatesRender.bind(this);
    this.onEventClick = this.onEventClick.bind(this);
    this.onDateClick = this.onDateClick.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.onDestroy = this.onDestroy.bind(this);
    this.onEventResize = this.onEventResize.bind(this);
    this.onDisplayModeChange = this.onDisplayModeChange.bind(this);
    this.onUserFilterChange = this.onUserFilterChange.bind(this);
    this.onSavePresence = this.onSavePresence.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getData = this.getData.bind(this);
    this.eventTransform = this.eventTransform.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }

  componentDidMount () {
    (window as any).pica = this;
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.fetchAdditional();
    live.observe(LiveEvents.GlobalBell, this.onBell);
  }

  updateWindowDimensions() {
    this.setState({ window: { width: window.innerWidth, height: window.innerHeight }})
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    live.cancel(LiveEvents.GlobalBell, this.onBell);
  }

  private onBell = () => {
    this.fetch();
  }
  
  async fetch() {
    let dateFilter = `startDate=${this.state.dateStart.toISOString()}&endDate=${this.state.dateEnd.toISOString()}`
    const res = await axios.get(`/events?${dateFilter}`)
    const events = res.data.events.map((e: any) => new CalendarEvent(e))
    const presencesRes = await axios.get(`/presences?${dateFilter}`)
    const presences = presencesRes.data.presences.map((e: any) => new Presence(e))
    this.setState({
      events,
      presences,
      isLoading: false
    }, () => {
      this.calendarRef.current.calendar.refetchEvents()
    })
  }

  async onDestroy(event: CalendarEvent) {
    await axios.delete(`/events/${event.id}`);
    this.setState({ showDialog: false }, () => this.fetch())
  }

  async fetchAdditional() {
    this.setState({ isLoading: true }, async () => {
      const usersRes = await axios.get('/users');
      const roomsRes = await axios.get('/rooms');
      this.setState({
        users: usersRes.data.users.map((u: any) => new User(u)),
        rooms: roomsRes.data.rooms.map((r: any) => new Room(r))
      }, async () => {
        await this.fetch();
        this.calendarRef.current.calendar.refetchResources()
      })
    })
  }

  next() {
    this.calendarRef.current.getApi().next()
  }

  nextNext() {
    this.calendarRef.current.getApi().nextNext()
  }

  prev() {
    this.calendarRef.current.getApi().prev()
  }

  prevPrev() {
    this.calendarRef.current.getApi().prevPrev()
  }

  formatTitle(_start: Date, _end: Date) {
    const start = moment(_start);
    const end = moment(_end);
    const weekDay = weekDays[start.toDate().getDay()];

    if (this.state.displayMode.value === DisplayModeType.DAY) {
      return `${weekDay} ${start.format('DD.MM.YYYY')}`
    }

    return `${start.format('DD.MM.YYYY')} - ${end.format('DD.MM.YYYY')}`
  }

  async onDatesRender(info: {view: any, el: any}) {
    this.setState({
      dateStart: info.view.currentStart,
      dateEnd: info.view.currentEnd,
      title: this.formatTitle(info.view.currentStart, info.view.currentEnd)
    }, () => this.fetch())
  }

  onEventResize(info: any) {
    console.log('onEventResize', info);
    // const event = new CalendarEvent(info.event)
    let event = this.state.events.find((e: any) => e.id === info.event.id);
    if (!event) {
      event = new CalendarEvent(info.event);
    }
    event.start = info.event.start;
    event.end = info.event.end;
    event.duration = (info.event.end.getTime() - info.event.start.getTime()) / 60000;
    this.updateEvent(event, true);
  }

  onEventClick(info:any) {
    const _event = info.event
    const resources = _event.getResources()

    if (resources.length > 0 && resources[0].id === 'presence') {
      let presence = this.state.presences.find(e => e.id === info.event.id)
      if (!presence) {
        presence = new Presence(info.event)
      }
      this.setState({
        selectedPresence: presence,
        showPresence: true
      })
    } else {
      let event = this.state.events.find(e => e.id === info.event.id)
      if (!event) {
        event = new CalendarEvent(info.event)
      }
      this.setState({
        selected: event,
        showDialog: true
      })
    }
  }

  onDateClick(info: any) {
    if (info.allDay || (info.resource && info.resource.id === 'presence')) {
      this.setState({
        showPresence: true,
        selectedPresence: new Presence({
          start: info.date,
          end: moment(info.date).add(8, 'hours'),
          user: this.props.user
        })
      })
      return
    }

    const room = this.state.rooms.find(room => info.resource && info.resource._resource && room.id === info.resource._resource.id);

    this.setState({
      selected: new CalendarEvent({
        allDay: info.allDay,
        start: info.date,
        end: moment(info.date).add(30, 'minutes'),
        duration: 30,
        resourceId: info.resource && info.resource._resource ? info.resource._resource.id : undefined,
        room
      }),
      showDialog: true
    })
  }

  async updateEvent(event: CalendarEvent, apply: boolean) {
    event.refresh()
    if (apply) {
      if (event.exists) {
        await axios.put(`/events/${event.id}`, event)
      } else {
        await axios.post('/events', event)
      }
    }
    this.setState( { showDialog: false }, () => this.fetch())
  }

  onChange(event: CalendarEvent) {
    this.setState({ selected: event })
  }

  onDisplayModeChange(displayMode: DisplayMode) {
    this.setState({ displayMode })
    const cal = this.calendarRef.current.getApi()
    if (cal) {
      cal.changeView(displayMode.value)
      setTimeout(() => {
        cal.refetchEvents()
      }, 100)
    }
  }

  onUserFilterChange(user: User) {
    this.setState({ userFilter: user })
  }

  async onSavePresence(presence: Presence) {
    if (presence.exists) {
      await axios.put(`/presences/${presence.id}`, presence.json)
    } else {
      await axios.post('/presences', presence.json)
    }
    this.setState({
      showPresence: false
    }, () => this.fetch())
    
  }

  private onCopy = () => {
    if (this.state.displayMode.value === DisplayModeType.DAY) {
      console.log('day copy');
      this.setState({
        clipboard: [...this.state.events.filter(e => e.start.getDate() === this.state.dateStart.getDate())],
        clipboardType: DisplayModeType.DAY
      })
    }

    if (this.state.displayMode.value === DisplayModeType.WEEK) {
      console.log('week copy')
      this.setState({
        clipboard: [...this.state.events.filter(e => 
          e.start.getDate() >= this.state.dateStart.getDate() &&
          e.start.getDate() <= this.state.dateEnd.getDate())],
        clipboardType: DisplayModeType.WEEK
      })
    }
  }

  private onPaste = () => {
    if (this.state.displayMode.value === this.state.clipboardType) {
      const transformed = [] as CalendarEvent[];
      this.state.clipboard.forEach(_event => {
        const event = new CalendarEvent(_event);
        if (this.state.clipboardType === DisplayModeType.DAY) {
          event.start.setDate(this.state.dateStart.getDate());
          event.start.setMonth(this.state.dateStart.getMonth());
          event.start.setFullYear(this.state.dateStart.getFullYear());
          event.end.setDate(this.state.dateStart.getDate());
          event.end.setMonth(this.state.dateStart.getMonth());
          event.end.setFullYear(this.state.dateStart.getFullYear());
        }

        if (this.state.clipboardType === DisplayModeType.WEEK) {
          const date = moment(this.state.dateStart).add(event.start.getDay() - 1, 'days').toDate();
          event.start.setDate(date.getDate());
          event.start.setMonth(date.getMonth());
          event.start.setFullYear(date.getFullYear());
          event.end.setDate(date.getDate());
          event.end.setMonth(date.getMonth());
          event.end.setFullYear(date.getFullYear());
        }
        
        delete (event as any).id;
        transformed.push(event);
      });

      console.log('transformed', transformed);
      this.setState({
        events: [...this.state.events, ...transformed]
      }, () => {
        this.calendarRef.current.calendar.refetchEvents()
      });
    } else {
      alert('Funkcia je dostupná len pri rovnakom type zobrazenia (ďeň/týždeň)')
    }
  }

  private onClipboardSave = () => {
    if (this.state.displayMode.value === this.state.clipboardType) {
      const transformed = [] as CalendarEvent[];
      this.state.clipboard.forEach(_event => {
        const event = new CalendarEvent(_event);
        if (this.state.clipboardType === DisplayModeType.DAY) {
          event.start.setDate(this.state.dateStart.getDate());
          event.start.setMonth(this.state.dateStart.getMonth());
          event.start.setFullYear(this.state.dateStart.getFullYear());
          event.end.setDate(this.state.dateStart.getDate());
          event.end.setMonth(this.state.dateStart.getMonth());
          event.end.setFullYear(this.state.dateStart.getFullYear());
        }

        if (this.state.clipboardType === DisplayModeType.WEEK) {
          const date = moment(this.state.dateStart).add(event.start.getDay() - 1, 'days').toDate();
          event.start.setDate(date.getDate());
          event.start.setMonth(date.getMonth());
          event.start.setFullYear(date.getFullYear());
          event.end.setDate(date.getDate());
          event.end.setMonth(date.getMonth());
          event.end.setFullYear(date.getFullYear());
        }
        
        delete (event as any).id;
        transformed.push(event);
      });

      transformed.forEach(event => {
        queue.add(async () => {
          await axios.post('/events', event);
        }).then(() => {
          if (queue.getPendingLength() === 0 && queue.getQueueLength() === 0) {
            this.fetch()
          }
        })
      })
      this.setState({
        events: [...this.state.events, ...transformed]
      }, () => {
        this.calendarRef.current.calendar.refetchEvents()
      });
    } else {
      alert('Funkcia je dostupná len pri rovnakom type zobrazenia (ďeň/týždeň)')
    }
  }

  getData(info: any, successCallback: any, failureCallback: any) {
    const presences = this.state.presences.map(this.eventTransform)
    successCallback([...this.state.events, ...presences])
  }

  eventTransform(event: any) {
    if (event instanceof Presence) {
      event.allDay = this.state.displayMode.value !== DisplayModeType.DAY
      event.title = event.getTitle()
    } else {
      if (event.color[0] === '#' && event.isPast) {
        event.color = convertHex(event.color, 60);
      }

      const now = new Date().getTime();
      const DIFF = 1000 * 60 * 15;
      if (event.present && (now - event.start.getTime() <= DIFF)) {
        event.borderColor = invertColor(event.color);
      } else {
        event.borderColor = event.color;
      }

    }
    return event
  }

  onDateChange(date: Date) {
    this.calendarRef.current.calendar.gotoDate(date)
  }

  render() {
    if (this.state.isLoading) {
      return <div className="loading">
        <div className="loading-container">
          <Spinner size={50} />
        </div>
      </div>
    }

    const resources = [
      {id: 'presence', title: 'Prítomní'},
      ...this.state.rooms.map(room => ({ id: room.id, title: room.name }))
    ]

    return <div className={`column ${this.state.displayMode.value}`}>
      <NavBar
        onLogout={this.props.onLogout}
        user={Session.user}
        userFilter={this.state.userFilter}
        users={this.state.users}
        onPrev={this.prev}
        onNext={this.next}
        onPrevPrev={this.prevPrev}
        onNextNext={this.nextNext}
        title={this.state.title}
        displayMode={this.state.displayMode}
        onDisplayModeChange={this.onDisplayModeChange}
        onUserFilterChange={this.onUserFilterChange}
        onAction={this.props.onAction}
        onDateChange={this.onDateChange}
        onCopy={this.onCopy}
        onPaste={this.onPaste}
        onClipboardSave={this.onClipboardSave}
      />

      <FullCalendar
        ref={this.calendarRef}
        defaultView="resourceTimeGridDay"
        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, resourceDayGridPlugin, resourceTimeGridPlugin]}
        height={this.state.window.height - 50}
        header={false}
        events={this.getData}
        refetchResourcesOnNavigate={true}
        minTime="05:00:00"
        maxTime="22:00:00"
        slotDuration="00:15:00"
        allDaySlot={false}
        businessHours={{
          daysOfWeek: [1, 2, 3, 4, 5, 6],
          firstDay: 1,
          startTime: 5,
          endTime: 24
        }}
        columnHeaderFormat={{
          weekday: 'short',
          month: 'numeric',
          day: 'numeric',
          omitCommas: true
        }}
        eventClick={this.onEventClick}
        dateClick={this.onDateClick}
        views={{
          dayGrid: {
            columnHeaderFormat: {
              weekday: 'short',
            }
          },
          timeGrid: {
            columnHeaderFormat: {
              weekday: 'short',
              month: 'numeric',
              day: 'numeric',
              omitCommas: true
            }
          }
        }}
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit'
        }}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit'
        }}
        eventDataTransform={this.eventTransform}
        eventResize={this.onEventResize}
        eventDrop={this.onEventResize}
        firstDay={1}
        locales={[locale]}
        locale="sk"
        resources={resources}
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        datesRender={this.onDatesRender}
        >
      </FullCalendar>

      <CalendarEventDialog
        isOpen={this.state.showDialog}
        event={this.state.selected}
        onClose={this.updateEvent}
        onDestroy={this.onDestroy}
        users={this.state.users}
        rooms={this.state.rooms}
        onChange={this.onChange}
      />

      { this.state.showPresence && <PresenceDialog
        presence={this.state.selectedPresence}
        users={this.state.users}
        onClose={() => this.setState({ showPresence: false })}
        onSave={this.onSavePresence}
      /> }
    </div>
  }
}