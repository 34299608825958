import React from 'react'
import {Button, Dialog, ControlGroup, FormGroup, TextArea, Checkbox} from '@blueprintjs/core'
import { withEvents, EventBusProps } from 'react-event-bus'
import {axios, formatDate, formatIsoDate, parseIsoDate, parseDate, SmsEvent, SmsUpdateEvent, today, yesterday, DAY_MS} from '../common'
import { showMessage, showError } from './toaster';
import {DateInput } from '@blueprintjs/datetime'
import produce from 'immer'

export type CheckMap = { [id: string]: boolean }

interface Props {
  isOpen: boolean
  onClose(): void
  EventBus: EventBusProps
}

interface State {
  records: any[]
  number: string
  message: string
  canSend: boolean
  isSending: boolean
  query: string
  dateFrom: Date
  dateTo: Date
  checkMap: CheckMap
}

class SmsDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      number: '',
      message: '',
      canSend: false,
      isSending: false,
      query: '',
      dateFrom: yesterday(),
      dateTo: today(),
      records: [],
      checkMap: {}
    };
    this.onSmsEvent = this.onSmsEvent.bind(this);
    this.props.EventBus.addEventListener('sms', this.onSmsEvent)
  }

  componentDidMount() {
    this.fetch()
  }

  async fetch() {
    const dateFrom = formatIsoDate(this.state.dateFrom);
    const dateTo = formatIsoDate(this.state.dateTo);
    const treatments = await axios.get(`/treatments?dateFrom=${dateFrom}&dateTo=${dateTo}`)
    console.log('treatments', treatments);
    if (Array.isArray(treatments.data)) {
      this.setState({ records: treatments.data, checkMap: {} });
    }
  }

  async onSmsEvent(event: SmsEvent) {
    this.setState({
      number: event.number,
      message: ''
    }, () => {
      this.validate()
    });
  }

  async send() {
    const data = {
      numbers: Object.keys(this.state.checkMap).filter(key => this.state.checkMap[key]),
      message: this.state.message
    };
    try {
      await axios.post('/messages', data);
      showMessage('SMS správa bola úspešne odoslaná');
      this.props.EventBus.dispatchEvent(new SmsUpdateEvent());
      this.props.onClose()
    } catch (e) {
      showError('SMS správu sa nepodarilo odoslať')
    }
  }

  async setNumber(number: string) {
    this.setState({ number }, () => {
      this.validate()
    })
  }

  async setMessage(message: string) {
    this.setState({ message }, () => {
      this.validate()
    })
  }

  validate() {
    this.setState({
      canSend: Object.keys(this.state.checkMap).length > 0
    })
  }

  renderItems() {
    const rows = this.state.records.map((record: any, index) => <tr key={`sms-${index}`}>
      <td>
        <Checkbox checked={this.state.checkMap[record.partner.phone]} onChange={async (evt: any) => {
          const state = produce(this.state, (newState) => {
            newState.checkMap[record.partner.phone] = evt.currentTarget.checked
          })
          this.setState(state, () => {
            this.validate()
          })
        }} />
      </td>
      <td>
        { formatDate(parseIsoDate(record.treatment.date)) }
      </td>
      <td>
        { [ record.partner.lastName, record.partner.firstName ].filter(i => i).join(' ')}
      </td>
      <td>
        { record.patient.name } ({ record.patient.kind })
      </td>
      <td>
        { record.partner.phone }
      </td>
    </tr>);
    return <table className="sms-dialog">
      <thead>
        <tr>
          <th>#</th>
          <th>Dátum</th>
          <th className="w-200">Klient</th>
          <th className="w-200">Pacient</th>
          <th className="w-130">Telefón</th>
        </tr>
      </thead>
      <tbody>
        { rows }
      </tbody>
    </table>
  }

  render() {
    if (!this.props.isOpen ) {
      return false
    }

    return <Dialog
      title="SMS - vakcinácie"
      isOpen={this.props.isOpen}
      style={{ width: 752 }}
      onClose={() => this.props.onClose() }>
      <div className="edit-dialog">
        <div className="row">
          <div className="column" style={{ marginRight: 12 }}>
            <FormGroup label="Dátum od" inline={true}>
              <DateInput
                parseDate={parseDate}
                formatDate={formatDate}
                value={this.state.dateFrom}
                onChange={date => {
                  this.setState({ dateFrom: date })
                }}
              />
            </FormGroup>
          </div>
          <div className="column">
            <FormGroup label="Dátum do" inline={true}>
              <DateInput
                  parseDate={parseDate}
                  formatDate={formatDate}
                  value={this.state.dateTo}
                  onChange={date => {
                    this.setState({ dateTo: date })
                  }}
              />
            </FormGroup>
          </div>
          <div className="column" style={{ width: 50 }}>
              <Button icon="refresh" minimal onClick={() => this.fetch()}/>
          </div>
        </div>
        <div className="row">
          { this.renderItems() }
        </div>
        <div className="row">
          <ControlGroup fill style={{ flex: 1 }}>
            <FormGroup label="Správa" style={{ flex: 1 }}>
              <TextArea
                placeholder="Zadajte správu..."
                className="text-area-plain-n-w"
                style={{ width: '100%' }}
                value={this.state.message}
                onChange={(event: any) => this.setMessage(event.target.value)}
              />
            </FormGroup>
          </ControlGroup>
        </div>
        <div className="row">
          <Button
            className="m-r-10"
            text="Odoslať"
            intent="success"
            icon="mobile-phone"
            disabled={!this.state.canSend}
            onClick={() => this.send() }
          />
          <Button
            text="Zavrieť"
            icon="cross"
            onClick={() => this.props.onClose()}
          />
        </div>
      </div>
    </Dialog>
  }
}

export default withEvents(SmsDialog)