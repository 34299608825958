import React from 'react'
import { Button, Icon, Navbar, MenuItem, Alignment, Colors, Tooltip, Position, Popover, PopoverInteractionKind } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import { withEvents, EventBusProps } from 'react-event-bus'
import { SmsEvent } from '../common'
import SmsDialog from '../components/sms_dialog'
import logo from '../assets/Logo_zverland_web.gif'
import '../styles/sidebar.scss'
import User from '../models/user'
import { DisplayMode, DisplayModes } from '../pages/calendar'
import Action, { Actions } from '../models/action'
import { DatePicker } from '@blueprintjs/datetime'
import { live, LiveEvents } from '../live';

import 'moment/locale/sk'
import MomentLocaleUtils from 'react-day-picker/moment'
const DisplayModeSelect = Select.ofType<DisplayMode>()
const UserSelect = Select.ofType<User>()
const ActionSelect = Select.ofType<Action>()

interface NavBarProps {
  user: any
  title: string
  userFilter: User
  users: User[]
  onPrev: () => void
  onNext: () => void
  onPrevPrev: () => void
  onNextNext: () => void
  onLogout: () => void
  EventBus: EventBusProps
  displayMode: DisplayMode
  onDisplayModeChange: (displayMode: DisplayMode) => void
  onUserFilterChange: (user: User) => void
  onAction: (action: Action) => void
  onDateChange: (date: Date) => void
  onCopy: () => void
  onPaste: () => void
  onClipboardSave: () => void
}

interface NavBarState {
  smsOpen: boolean
}

class NavBar extends React.Component<NavBarProps, NavBarState> {
  constructor(props: NavBarProps) {
    super(props)
    this.state = {
      smsOpen: false
    }
    this.props.EventBus.addEventListener('sms', (event: SmsEvent) => {
      this.setState({ smsOpen: true })
    })
  }

  componentDidMount() {
    live.connect();
    live.observe(LiveEvents.GlobalBell, this.onBell);
    (window as any).live = this;
  }

  private onBell = (data: { client: string, doctor: string }) => {
  }

  userRenderer(user: User, options: any) {
    return <MenuItem
      key={user.id || 'new-user'}
      id={user.id}
      text={user.name}
      icon={<Icon icon="user" color={user.color}/>}
      onClick={options.handleClick}
    />
  }

  displayModeRenderer(displayMode: DisplayMode, options: any) {
    return <MenuItem
      key={displayMode.value}
      id={displayMode.value}
      text={displayMode.title}
      onClick={options.handleClick}
    />
  }

  actionRenderer(action: Action, options: any) {
    return <MenuItem
      key={action.id}
      id={action.id}
      text={action.title}
      icon={action.icon}
      onClick={options.handleClick}
    />
  }

  onActionChange(action: Action) {

  }

  render() {
    return <Navbar
      className="navbar"
      fixedToTop={true}
      style={{ display: 'flex' }}>
      <Navbar.Group align={Alignment.LEFT} style={{ flex: 1 }}>
        <Navbar.Heading>
          <div className="row">
            <img alt="logo" className="logo-zverland-nav" src={logo}/>
          </div>
        </Navbar.Heading>
        <DisplayModeSelect
          filterable={false}
          items={DisplayModes}
          itemRenderer={this.displayModeRenderer}
          onItemSelect={this.props.onDisplayModeChange}>
          <Button minimal rightIcon="caret-down">{this.props.displayMode.title}</Button>
        </DisplayModeSelect>
        <UserSelect
          filterable={false}
          items={[new User({ name: 'Všetci', color: 'black' }), ...this.props.users]}
          itemRenderer={this.userRenderer}
          onItemSelect={this.props.onUserFilterChange}>
          <Button minimal rightIcon="caret-down">{this.props.userFilter.exists ? this.props.userFilter.name : 'Všetci'}</Button>
        </UserSelect>
      </Navbar.Group>
      <Navbar.Group align={Alignment.CENTER} style={{ flex: 2, justifyContent: 'center', color: 'white'}}>
        <Button onClick={this.props.onPrev} icon="chevron-left" minimal={true} color={Colors.WHITE} className="white"/>
        <div className="calendar-title">
          <Popover interactionKind={PopoverInteractionKind.CLICK} captureDismiss={true}>
            <span>{ this.props.title }</span>
            <DatePicker
              locale="sk"
              localeUtils={MomentLocaleUtils}
              dayPickerProps={{
                firstDayOfWeek: 1,
              }}
              onChange={this.props.onDateChange}
            />
          </Popover>
        </div>
        <Button onClick={this.props.onNext} icon="chevron-right" minimal={true} color={Colors.WHITE} className="white"/>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}  style={{ flex: 1, justifyContent: 'flex-end' }}>
        <Tooltip content="Kopírovať ošetrenia" position={Position.BOTTOM}>
          <Button minimal icon="clipboard" onClick={this.props.onCopy}></Button>
        </Tooltip>
        <Tooltip content="Vložiť ošetrenia (náhlad)" position={Position.BOTTOM}>
          <Button minimal icon="duplicate" onClick={this.props.onPaste}></Button>
        </Tooltip>
        <Tooltip content="Uložiť skopírované ošetrenia" position={Position.BOTTOM}>
          <Button minimal icon="tick" onClick={this.props.onClipboardSave}></Button>
        </Tooltip>
        <ActionSelect
          filterable={false}
          items={Actions}
          itemRenderer={this.actionRenderer}
          onItemSelect={this.props.onAction}>
          <Button minimal rightIcon="caret-down">Akcie</Button>
        </ActionSelect>
        <Tooltip content="Odhlásenie" position={Position.BOTTOM}>
          <Button
            className="white"
            minimal={true}
            color={Colors.WHITE}
            icon="user"
            text={`${this.props.user.firstName} ${this.props.user.lastName}`}
            onClick={(event: any) => { this.props.onLogout() }}
          />
        </Tooltip>
      </Navbar.Group>
      <SmsDialog
        isOpen={this.state.smsOpen}
        onClose={() => this.setState({ smsOpen: false })}
      />
    </Navbar>
  }
}

export default withEvents(NavBar)