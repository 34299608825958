import React from 'react'
import {
  Button,
  Classes,
  ControlGroup,
  Dialog,
  FormGroup,
  Icon,
  InputGroup,
  MenuItem,
  TextArea,
  Tag,
  Checkbox
} from '@blueprintjs/core'
import { DateInput } from '@blueprintjs/datetime'
import { Select } from '@blueprintjs/select'
import { axios, DateFormatter } from '../common'
import CalendarEvent, { CalendarEventType } from '../models/calendar_event'
import Client from '../models/client'
import Patient from '../models/patient'
import User from '../models/user'
import Room from '../models/room'
import Duration from '../models/duration'
import { live, LiveEvents } from '../live';
import 'moment/locale/sk'
import MomentLocaleUtils from 'react-day-picker/moment'
import moment from 'moment'
import uuid from 'uuid'
import Recall from '../models/recall'
import EventType from '../models/event_type'

const ClientSelect = Select.ofType<Client>()
const PatientSelect = Select.ofType<Patient>()
const UserSelect = Select.ofType<User>()
const DurationSelect = Select.ofType<Duration>()
const RoomSelect = Select.ofType<Room>()
const RecallSelect = Select.ofType<Recall>()
const TypeSelect = Select.ofType<EventType>()

const Durations = [
  new Duration('15 minút', 15),
  new Duration('30 minút', 30),
  new Duration('45 minút', 45),
  new Duration('Hodina', 60),
  new Duration('Hodina a pol', 90),
  new Duration('2 hodiny', 120),
  new Duration('3 hodiny', 180),
  new Duration('4 hodiny', 240),
  new Duration('5 hodín', 300)
];

const Recalls = [
  new Recall(0, 'Vyberte'),
  new Recall(7, 'Týždeň'),
  new Recall(14, '2 týždne'),
  new Recall(30, 'Mesiac'),
  new Recall(60, '2 mesiace'),
  new Recall(90, '3 mesiace'),
  new Recall(180, 'Pol roka'),
  new Recall(365, 'Rok'),
]

const Types = [
  new EventType(0, 'Vyberte'),
  new EventType(1, 'Oftalmológia'),
  new EventType(2, 'Kardiológia'),
  new EventType(3, 'Dermatológia'),
  new EventType(4, 'Kontrola'),
]

interface Props {
  isOpen: boolean
  event: CalendarEvent
  onClose: (event: CalendarEvent, apply: boolean) => void
  onChange: (event: CalendarEvent) => void
  onDestroy: (event: CalendarEvent) => void
  users: User[]
  rooms: Room[]
}

interface State {
  form: CalendarEvent
  clients: Client[]
  clientQuery: string
  patients: Patient[]
  patientQuery: string
  valid: boolean
}

export default class CalendarEventDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      clients: [],
      clientQuery: '',
      form: props.event,
      valid: props.event.exists,
      patients: [],
      patientQuery: ''
    }
    this.onWorkChange = this.onWorkChange.bind(this)
    this.onUserSelect = this.onUserSelect.bind(this)
    this.onRoomSelect = this.onRoomSelect.bind(this)
    this.onPatientSelect = this.onPatientSelect.bind(this)
    this.onDurationSelect = this.onDurationSelect.bind(this)
    this.onClientQueryChange = this.onClientQueryChange.bind(this)
    this.onPatientQueryChange = this.onPatientQueryChange.bind(this)
  }

  componentWillReceiveProps (nextProps: Props) {
    this.setState({
      form: nextProps.event,
    })
  }

  async onWorkChange(event: CalendarEvent) {
    if (event.client.exists) {
      event.type = CalendarEventType.JOB
      event.allDay = false
      this.setState({
        form: event,
        valid: false,
      })
    }
  }

  update (event: any) {
    const form = this.state.form
    const value = event.target.value
    const field = event.target.name

    if (field === 'note') {
      form.note = value
    }
    // form[field] = event.target.value
    this.setState({ form }, () => {
      this.props.onChange(this.state.form)
    })
  }

  updateClient(event: any) {
    const form = this.state.form
    const field = event.target.name
    const value = event.target.value
    if (field === 'firstName') form.client.firstName = value
    if (field === 'lastName') form.client.lastName = value
    if (field === 'address') form.client.address = value
    if (field === 'zipCode') form.client.zipCode = value
    if (field === 'city') form.client.city = value
    if (field === 'company') form.client.company = value
    if (field === 'email') form.client.email = value
    if (field === 'phone') form.client.phone = value
    if (field === 'note') form.client.note = value
    this.setState({ form })
  }

  updatePatient(event: any) {
    const form = this.state.form
    const field = event.target.name
    const value = event.target.value
    if (field === 'name') form.patient.name = value
    if (field === 'note') form.patient.note = value
    if (field === 'race') form.patient.race = value
    this.setState({ form })
  }

  updateDate(date: any) {
    const form = this.state.form
    form.start = date
    this.setState({ form })
  }

  updateTime(date: any) {
    const form = this.state.form
    form.start = date
    this.setState({ form })
  }

  updateEndTime(date: any) {
    const form = this.state.form
    if (date > form.end) {
      form.end = moment(form.end).add(5, 'minutes').toDate()
    } else {
      form.end = moment(form.end).subtract(5, 'minutes').toDate()
    }
    this.setState({ form })
  }

  async onClientSelect(client: Client) {
    const form = this.state.form;
    form.client = client;
    form.client_id = client.id;
    this.setState({ form }, async () => {
      const res = await axios.get(`/patients?client_id=${client.id}`);
      this.setState({ patients: res.data.patients.map((p: any) => new Patient(p)) });
    })
  }

  async onClientQueryChange(query: string, event: any) {
    this.setState({ clientQuery: query }, async () => {
      const response = await axios.get(`/clients?query=${encodeURI(query)}&limit=10`);
      this.setState({
        clients: response.data.clients.map((c: any) => new Client(c)),
      });
    })
  }

  newClientFromQuery(query: string) {
    return new Client({ lastName: query })
  }

  newClientRenderer(query: string, active: boolean, handleClick: any) {
    return <MenuItem
      key="new-client-item"
      text={`Nový klient: ${query}`}
      onClick={handleClick}
    />
  }

  clientRenderer(item: Client, options: any) {
    return <MenuItem
      key={item.id || uuid()}
      text={item.name}
      label={item.patients.map(patient => patient.name).join(', ')}
      onClick={options.handleClick}
    />
  }

  patientRenderer(item: Patient, options: any) {
    return <MenuItem
      key={item.id || uuid()}
      text={item.name}
      onClick={options.handleClick}
    />
  }

  async onPatientSelect(patient: Patient) {
    const form = this.state.form
    form.patient = patient
    form.patient_id = patient.id
    this.setState({ form })
  }

  async onPatientQueryChange(query: string, event: any) {
    this.setState({ clientQuery: query }, async () => {
      const response = await axios.get(`/patients?query=${encodeURI(query)}&limit=10`)
      this.setState({
        patients: response.data.patients.map((p: any) => new Patient(p)),
      })
    })
  }

  newPatientFromQuery(query: string) {
    return new Patient({ lastName: query })
  }

  newPatientRenderer(query: string, active: boolean, handleClick: any) {
    return <MenuItem
      key="new-patient-item"
      text={`Nový pacient: ${query}`}
      onClick={handleClick}
    />
  }

  userRenderer(item: User, options: any) {
    return <MenuItem
      key={item.id || uuid()}
      text={item.name}
      icon={<Icon icon="user" color={item.color} />}
      onClick={options.handleClick}
    />
  }

  durationRenderer(item: Duration, options: any) {
    return <MenuItem
      key={item.duration}
      text={item.id}
      icon="time"
      onClick={options.handleClick}
    />
  }

  recallRenderer(item: Recall, options: any) {
    return <MenuItem
      key={item.id}
      text={item.title}
      icon="calendar"
      onClick={options.handleClick}
    />
  }

  typeRenderer(item: EventType, options: any) {
    return <MenuItem
      key={item.id}
      text={item.title}
      onClick={options.handleClick}
    />
  }

  onRecallSelect = (item: Recall, options: any) => {
    const event = this.state.form
    event.recall = item.id
    this.props.onChange(event)
  }

  onTypeSelect = (item: EventType, options: any) => {
    const event = this.state.form
    event.type = item.id
    this.props.onChange(event)
  }

  onUserSelect(item: User) {
    const event = this.state.form
    event.user = item
    event.user_id = item.id
    this.props.onChange(event)
  }

  onFinishedChange = (evt: any) => {
    const event = this.state.form
    event.finished = evt.currentTarget.checked;
    this.props.onChange(event)
  }

  getDuration() {
    const duration = Durations.find(d => d.duration === this.state.form.duration)
    if (duration) {
      return duration
    }
    return new Duration(`${this.state.form.duration} minút`, this.state.form.duration)
  }

  onDurationSelect(duration: Duration) {
    const form = this.state.form
    form.duration = duration.duration
    form.end = moment(this.state.form.start).add(duration.duration, 'minutes').toDate()
    this.setState({ form })
  }

  roomRenderer(room: Room, options: any) {
    return <MenuItem
      key={room.id}
      text={room.name}
      icon="time"
      onClick={options.handleClick}
    />
  }

  onRoomSelect(room: Room) {
    const form = this.state.form;
    form.room = room;
    this.setState({ form })
  }

  private bell = async () => {
    await axios.get(`/events/${this.props.event.id}/present`);
    live.send(LiveEvents.Bell, { 
      client: this.state.form.client, 
      doctor: this.state.form.user.name
    })
  }

  render () {
    return <Dialog
      isOpen={this.props.isOpen}
      title={this.state.form.exists ? "Upravenie udalosti" : 'Nová udalosť'}
      onClose={() => { this.props.onClose(this.state.form, false) }}
      style={{width: `960px`}}>
      <div className={`${Classes.DIALOG_BODY} edit-dialog`} style={{ margin: 0 }}>
        <div className="row">
          <div className="column" style={{width: 200}}>
            <strong className="dialog-subtitle">
              <Tag intent={this.state.form.user.exists ? 'success' : 'warning'} round>1</Tag> LEKÁR
            </strong>
            <div className="row">
              <ControlGroup fill style={{flex: 1}}>
                <FormGroup>
                  <UserSelect
                    items={this.props.users}
                    itemRenderer={this.userRenderer}
                    activeItem={this.state.form.user}
                    onItemSelect={this.onUserSelect}>
                    <Button
                      minimal
                      text={this.state.form.user.exists ? this.state.form.user.name : 'Vyberte'}
                      icon={<Icon icon="user" color={this.state.form.user.color}/>}
                      rightIcon="double-caret-vertical" />
                  </UserSelect>
                </FormGroup>
              </ControlGroup>
            </div>

            <div className="row">
              <ControlGroup fill style={{flex: 1}}>
                <FormGroup
                  label="Dátum">
                  <DateInput
                    inputProps={{style: { width: '100%' }}}
                    formatDate={(date, locale) => DateFormatter.formatDate(date, locale, true)}
                    parseDate={(date, locale) => DateFormatter.parseDate(date, undefined)}
                    className="text-input-plain"
                    value={this.state.form.start}
                    locale="sk"
                    localeUtils={MomentLocaleUtils}
                    dayPickerProps={{
                      firstDayOfWeek: 1,
                    }}
                    onChange={(event: any) => this.updateDate(event)}
                  />
                </FormGroup>
              </ControlGroup>
            </div>

            <ControlGroup fill style={{flex: 1}}>
              <FormGroup label="Trvanie">
                <DurationSelect
                  items={Durations}
                  itemRenderer={this.durationRenderer}
                  activeItem={this.getDuration()}
                  onItemSelect={this.onDurationSelect}>
                  <Button
                    minimal
                    fill
                    text={this.state.form.duration ? this.getDuration().id : 'Vyberte'}
                    icon="time"
                    rightIcon="double-caret-vertical" />
                </DurationSelect>
              </FormGroup>
            </ControlGroup>

            <ControlGroup fill style={{flex: 1}}>
              <FormGroup label="Miestnosť">
                <RoomSelect
                  items={this.props.rooms}
                  itemRenderer={this.roomRenderer}
                  activeItem={this.state.form.room}
                  onItemSelect={this.onRoomSelect}>
                  <Button
                    minimal
                    fill
                    text={this.state.form.room.exists ? this.state.form.room.name : 'Vyberte'}
                    icon="time"
                    rightIcon="double-caret-vertical" />
                </RoomSelect>
              </FormGroup>
            </ControlGroup>
          </div>

          <div className="column" style={{width: 240, marginLeft: 14, marginRight: 14}}>
            <strong className="dialog-subtitle">
              <Tag intent={this.state.form.client.lastName.length > 0 ? 'success' : 'warning'} round>2</Tag> KLIENT
            </strong>

            <div className="row">
              <ControlGroup fill style={{flex: 1}}>
                <FormGroup>
                  <ClientSelect
                    query={this.state.clientQuery}
                    items={this.state.clients}
                    createNewItemRenderer={this.newClientRenderer}
                    createNewItemFromQuery={this.newClientFromQuery}
                    itemRenderer={this.clientRenderer}
                    onQueryChange={this.onClientQueryChange}
                    activeItem={this.state.form.client}
                    onItemSelect={(item: any) => { this.onClientSelect(item) }}>
                    <Button minimal text={this.state.form.client.id ? this.state.form.client.name : 'Vyberte'} icon="user" rightIcon="double-caret-vertical" />
                  </ClientSelect>
                </FormGroup>
              </ControlGroup>
              <Button
                text="Nový"
                intent="primary"
                minimal
                icon="plus"
                style={{ height: '30px' }}
                onClick={(e: any) => {
                  const form = this.state.form
                  form.client_id = ''
                  form.client = new Client({})
                  this.setState({ form })
                }}
              />
            </div>

            <FormGroup label="Meno" className="m-r-20">
              <InputGroup
                name="firstName"
                placeholder="Meno"
                className="text-input-plain"
                value={this.state.form.client.firstName}
                onChange={(event: any) => this.updateClient(event)}
              />
            </FormGroup>

            <FormGroup label="Priezvisko">
              <InputGroup
                name="lastName"
                placeholder="Priezvisko"
                className="text-input-plain"
                value={this.state.form.client.lastName}
                onChange={(event: any) => this.updateClient(event)}
              />
            </FormGroup>

            <FormGroup label="Telefón" className="m-r-20">
              <InputGroup
                name="phone"
                placeholder="Phone"
                className="text-input-plain"
                value={this.state.form.client.phone}
                onChange={(event: any) => this.updateClient(event)}
              />
            </FormGroup>
          </div>

          <div className="column" style={{width: 240}}>
            <strong className="dialog-subtitle">
                <Tag intent={this.state.form.patient.name.length > 0 ? 'success' : 'warning'} round>3</Tag> PACIENT
              </strong>
            <div className="row">
              <ControlGroup fill style={{flex: 1}}>
                <FormGroup>
                  <PatientSelect
                    query={this.state.patientQuery}
                    items={this.state.patients}
                    createNewItemRenderer={this.newPatientRenderer}
                    createNewItemFromQuery={this.newPatientFromQuery}
                    itemRenderer={this.patientRenderer}
                    onQueryChange={this.onPatientQueryChange}
                    activeItem={this.state.form.patient}
                    onItemSelect={this.onPatientSelect}>
                    <Button minimal text={this.state.form.patient.id ? this.state.form.patient.name : 'Vyberte'} icon="user" rightIcon="double-caret-vertical" />
                  </PatientSelect>
                </FormGroup>
              </ControlGroup>
              <Button
                text="Nový"
                intent="primary"
                minimal
                icon="plus"
                style={{ height: '30px' }}
                onClick={(e: any) => {
                  const form = this.state.form
                  form.patient_id = ''
                  form.patient = new Patient({})
                  this.setState({ form })
                }}
              />
            </div>

            <FormGroup label="Meno">
              <InputGroup
                name="name"
                placeholder="Meno"
                className="text-input-plain"
                style={{width: '240px'}}
                value={this.state.form.patient.name}
                onChange={(event: any) => this.updatePatient(event)}
              />
            </FormGroup>

            <FormGroup label="Druh">
              <InputGroup
                name="race"
                placeholder="Rasa"
                className="text-input-plain"
                style={{width: '240px'}}
                value={this.state.form.patient.race}
                onChange={(event: any) => this.updatePatient(event)}
              />
            </FormGroup>
          </div>

          <div className="column" style={{width: 200, marginLeft: 24}}>
            <strong className="dialog-subtitle">
              <Tag intent={this.state.form.client.lastName.length > 0 ? 'success' : 'warning'} round>4</Tag> RECALL
            </strong>

            <div className="row">
              <RecallSelect
                filterable={false}
                items={Recalls}
                itemRenderer={this.recallRenderer}
                activeItem={Recalls.find(r => r.id === this.state.form.recall)}
                onItemSelect={this.onRecallSelect}>
                <Button minimal text={Recalls.find(r => r.id === this.state.form.recall) ? Recalls.find(r => r.id === this.state.form.recall)!.title : 'Vyberte'} icon="user" rightIcon="double-caret-vertical" />
              </RecallSelect>
            </div>

            <div className="row" style={{ marginTop: 14 }}>
              <FormGroup label="Typ ošetrenia">
                <TypeSelect
                  filterable={false}
                  items={Types}
                  itemRenderer={this.typeRenderer}
                  activeItem={Types.find(r => r.id === this.state.form.type)}
                  onItemSelect={this.onTypeSelect}>
                  <Button minimal text={Types.find(r => r.id === this.state.form.type) ? Types.find(r => r.id === this.state.form.type)!.title : 'Vyberte'} icon="user" rightIcon="double-caret-vertical" />
                </TypeSelect>
              </FormGroup>
            </div>

            <div className="row" style={{ marginTop: 28, marginLeft: 2 }}>
              <Checkbox label="Ukončený prípad" checked={this.state.form.finished} onChange={this.onFinishedChange} />  
            </div>
          </div>

        </div>
        
        <div className="row">
          <ControlGroup fill style={{flex: 1}}>
            <FormGroup label="Poznámka">
              <TextArea
                fill
                name="note"
                placeholder="Poznámka"
                className="text-area-plain-n-w"
                value={this.state.form.note}
                onChange={(event: any) => this.update(event)}
              />
            </FormGroup>
          </ControlGroup>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <div className="row" style={{marginTop: '20px', height: 32, width: '100%'}}>
            <div className="row" style={{justifyContent: 'flex-start', flex: 1}}>
              <Button
                text="Zavrieť"
                icon="cross"
                style={{ margin: 0 }}
                className="m-r-20"
                onClick={() => { this.props.onClose(this.state.form, false) }}
              />
              <Button
                text="Zmazať"
                icon="trash"
                intent="danger"
                onClick={() => { this.props.onDestroy(this.state.form) }}
              />
            </div>
            <div className="row" style={{justifyContent: 'flex-end', flex: 1}}>
              <Button 
                icon="notifications"
                intent="success"
                onClick={this.bell}
              />
              <Button
                text="Potvrdiť"
                icon="tick"
                intent="primary"
                // disabled={!this.state.valid}
                onClick={() => { this.props.onClose(this.state.form, true) }}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  }
}