import BaseModel from './base_model'
import User from './user'
import { formatTime } from '../common'

export default class Presence extends BaseModel {
  allDay: boolean = false
  start: Date
  end: Date
  user: User
  title: string
  color: string
  resourceId: string

  user_id: string

  constructor(data: any) {
    super(data.id)
    this.start = data.start ? new Date(data.start) : new Date()
    this.end = data.end ? new Date(data.end) : new Date()
    this.user = data.user ? new User(data.user) : new User({})
    this.user_id = data.user_id
    this.color = data.color
    this.resourceId = data.resourceId
    this.title = this.getTitle()
  }

  getTitle() {
    return this.allDay ? `${this.user.name} - ${formatTime(this.start)} ${formatTime(this.end)}` : this.user.name
  }

  get json() {
    return {
      start: this.start,
      end: this.end,
      user_id: this.user ? this.user.id : this.user_id
    }
  }
}