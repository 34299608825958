import BaseModel from './base_model'
import Patient from './patient'

export default class Client extends BaseModel {
  firstName: string
  lastName: string
  name: string
  address: string
  zipCode: string
  city: string
  company: string
  email: string
  phone: string
  note: string
  patients: Patient[]

  constructor(data: any) {
    super(data.id)
    this.firstName = data.firstName || ''
    this.lastName = data.lastName || ''
    const names = []
    if (this.firstName) {
      names.push(this.firstName)
    }
    if (this.lastName) {
      names.push(this.lastName)
    }
    this.name = names.join(' ').trim()
    this.address = data.address || ''
    this.zipCode = data.zipCode || ''
    this.city = data.city || ''
    this.company = data.company || ''
    this.email = data.email || ''
    this.phone = data.phone || ''
    this.note = data.note || ''
    this.patients = data.patients ? data.patients.map((item: any) => new Patient(item)) : []
  }

}