import BaseModel from './base_model'

export default class User extends BaseModel {
  name: string
  firstName: string
  lastName: string
  userName: string
  email: string
  password: string
  role: string
  color: string

  constructor(data: any) {
    super(data.id)
    this.name = data.name || ''
    this.userName = data.userName || ''
    this.firstName = data.firstName || ''
    this.lastName = data.lastName || ''
    this.email = data.email || ''
    this.password = data.password || ''
    this.role = data.role || 'user'
    this.color = data.color || '#08c'
  }

  get isUser() {
    return this.role === 'user'
  }

  get isManager() {
    return this.role === 'manager'
  }
}